import React, { Fragment } from 'react'
import { Styled } from 'theme-ui'

export default () => (
  <Fragment>
    Documentation written by{' '}
    <Styled.a href='https://ahrjarrett.com/' target='_blank'>
      Andrew Jarrett
    </Styled.a>
    .
    <br />
    In a love/hate relationship with JavaScript since 2015.
  </Fragment>
)
